* {
  font-family: "Poppins";
  font-weight: 400;
}
body {
  margin: 0;
  letter-spacing: 0.4px;
  background-color: #000;
}
@media (min-width: 1410px) {
  .container {
    max-width: 1380px;
  }
}

/* ______________________________Header______________________________ */

.header {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid #ffffff1a;
}
.navbar-light .navbar-brand {
  margin: 0;
}
.inverted {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2);
}

.navbar-expand-lg {
  display: block;
}
.header .navbar-expand-lg .navbar-collapse {
  flex-grow: unset;
  flex-basis: unset;
}
.BLogo,
.inverted .WLogo {
  display: none;
}
.inverted .BLogo {
  display: block;
}
.navbar-light.inverted .navbar-nav .nav-link a,
.navbar-light.inverted .navbar-nav .nav-item a {
  color: #000;
}

/* .inverted{
  color: #000000 !important;
} */
.header .inverted {
  background-color: #ffffff !important;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2);
  transition: 0.4s top cubic-bezier(0.3, 0.73, 0.3, 0.74);
  padding-top: 10px;
  padding-bottom: 10px;
}
.inner-header {
  display: flex;
  max-width: 1670px;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  padding: 0px 30px;
}
.titleFont h3 {
  font-size: 20px;
  color: #fff;
}
.titleFont h2 {
  font-size: 50px;
  color: #fff;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link a,
.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  transition: 0.4s ease;
  margin: 0px 10px;
}
.navbar-light .navbar-nav .nav-link.active a{
  color: #fe0040;
}

.header .fas {
  color: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.dropdown-item a {
  color: #000000 !important;
  text-decoration: none;
}
.dropdown-item:hover a {
  color: #FF0000 !important;
}
.dropdown-item:active {
  background-color: #e9ecef60;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #ff0000;
}
.navbar-light .navbar-nav .nav-link a:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: #FF0000 !important;
}
.navbar-light .navbar-nav .nav-link a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -5px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #FF0000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.navbar-light .navbar-nav .nav-link a:hover:after {
  width: 100%;
  left: 0;
}
.header .fas {
  color: #000;
  margin-left: -10px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: transparent;
}
.dropdown-item a {
  color: #000000;
  text-decoration: none;
}
/* toggle */
.navbar-toggler-icon:after {
  content: "\F0C9";
  font-family: "fontAwesome";
  color: white;
}

.header .inverted .navbar-toggler-icon:after {
  content: "\F0C9";
  font-family: "fontAwesome";
  color: black;
  border: none;
}
.navbar-light .navbar-toggler{
  border: none;
  padding: 0px;
}
.hidden-lg {
  display: none;
}
.w3-overlay {
  display: none;
}
.btn-primary:active:focus{
box-shadow: none;
}
.navbar-toggle {
  display: none;
}
.header button {
  color: #fff;
  background-color: transparent;
  border: none;
}
.header .inverted button {
  color: #000;
  /* background-color: transparent;
  border: none; */
}
.btn-navbar:focus-visible {
  outline: none;
}
.btn-navbar .btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  background-color: transparent;
  color: #fff;
}
.header button:focus {
  background-color: transparent;
  color: #000;
  box-shadow: none;
}
/* ______________________________________scroll-button______________________________________ */

.scroll-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  z-index: 1;
}

.scroll-icon:hover {
  background-color: #ff9898;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
  transition: 0.3s ease-in;
}

.scroll-icon {
  background-color: #ff0000;
  padding: 12px 16px;
  color: #fff;
  border-radius: 5px;
}
.otherPageBanner h2 {
  color: #ffffffa8;
  font-size: 17px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.otherPageBanner h6 {
  font-size: 58px;
  font-weight: 500;
  line-height: 62px;
  color: #ffffffff;
}
/* ___________________________Footer_____________________________ */
.footer {
  background-image: url(/static/media/footer.b912a735.jpg);
  padding: 55px 0px;
}
.Footerbox img {
  margin-bottom: 30px;
}
.footer-text {
  color: #fff;
  line-height: 35px;
}
.footer-text p {
  font-size: 14px;
  line-height: 35px;
  max-width: 350px;
  color: #fff;
}
.footer-text .list-unstyled a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  transition: 0.4s ease;
}
.footer-text .list-unstyled a:hover {
  color: #ff0000;
}
.footer-text h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 30px;
}
.social-icon ul{
  width:100%;
  text-align:center;
}
.social-icon li {
  width: 40px;
  background-color: #ffffff;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
  margin-right: 10px;
}
.social-icon li:hover {
  background-color: #ff0000;
}
.social-icon i {
  font-size: 18px;
  color: #FF0000;
  transition: 0.4s ease;
  padding: 10px 0px;
}
.social-icon li:hover i {
  color: #fff;
}

.footer-bottom {
  background-color: #000000;
  padding: 15px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}
.F-copywrite {
  font-size: 14px;
  margin: 0;
}
.contactUs {
  padding: 60px 0px;
}
.heart{
  color: #FF0000;
}
.FooterquickContact {
  max-width: 100%;
  line-height: 35px;
  margin-bottom: 20px;
}

.FooterquickContact p,
.FooterquickContact span {
  color: #fff;
  font-size: 16px;
}
.contactUsIconContain {
  cursor: pointer;
}
.contactUsIconContain span {
  display: inline-block;
  max-width: 288px;
  vertical-align: top;
  margin-left: 20px;
}
.contactUsIconContain i {
  color: #fff;
}
.contactUsIconContain:hover i {
  color: #ff0000;
}
a:link {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}
a:hover {
  color: red;
  background-color: transparent;
  text-decoration: underline;
}
a:visited {
  color: #fff;
  background-color: transparent;
  text-decoration: none;
}
/* ------------------------------------------------------------------------------------------
--------------------------------------------------------------------------Sign Up--------------------------------------------
------------------------------------------------------------------------------------------ */
.signupBanner {
  background-image: url(/static/media/SignUp.4ece4e13.png);
  background-repeat: no-repeat;
  padding: 33px;
}
.form-container {
  margin: 100px auto;
  width: 1000px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  border-radius: 10px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.form-content-left {
  border-radius: 10px 0 0 10px;
  position: relative;
}

.form-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 10px 0 0 10px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.form-success {
  text-align: center;
  font-size: 24px;
  margin-top: 80px;
  color: #fe0040;
}
.SuccessIcon {
  align-items: center;
}
.form-content-right {
  border-radius: 0 10px 10px 0;
  position: relative;
  background-color: #232f41;
}

.form {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h1 {
  font-size: 15px;
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;
  color: #fff;
}
.SignUpInTitle {
  font-size: 25px !important;
  text-align: center !important;
}
.form-inputs {
  width: 80%;
}

.form-inputs p {
  font-size: 0.8rem;
  margin-top: -35px !important;
  color: #f00e0e;
  margin-bottom: 15px !important;
}
.form .form-input-login span {
  color: #fe0040 !important;
  cursor: pointer;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  height: 40px;
  width: 100%;
  border: none;
}

.form-input::-webkit-input-placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 40px;
  border-radius: 5px;
  background-color: #fe0040;
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.form-input-btn:hover {
  cursor: pointer;
  transition: all 0.4s ease-out;
  background-color: #000;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}
.input-container { /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.form .icon {
  padding: 12px;
  background: #fe0040;
  color: white;
  min-width: 50px;
  text-align: center;
  border-radius: 5px 0px 0px 5px;
}

.input-field {
  width: 100%;
  border-radius: 0px 5px 5px 0px;
  outline: none;
  padding-bottom: 1px;
}
.signupSocial h2 {
  font-size: 16px;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 10px;
}
/* ----------------------------------------------------------------------
----------------------------------------------------------------------AboutUsText----------------------------------------------------------------------
---------------------------------------------------------------------- */

.AboutUs {
  margin-top: 20px;
  margin-bottom: 20px;
}
.AboutUsText {
  padding: 50px;
}
.AboutUsText h3 {
  font-size: 30px;
  font-weight: 400;
}
.AboutUsText p {
  line-height: 1.9;
  color: #8d8d8d;
  font-weight: 300;
  font-size: 18px;
}
.MainPartMiddel {
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.AboutUsImgMainDiv {
  padding: 0;
}
.AboutUsFirstMainPart {
  padding-left: 7%;
  padding-right: 7%;
  padding-bottom: 2%;
}
.AboutUsFirstTitle {
  font-size: 40px;
  line-height: 1.5;
  text-align: center;
  padding-right: 50px;
  padding-left: 50px;
  font-weight: 300;
  text-align: left;
}
.AboutUsFirstTitle p {
  color: #fe0040;
}
.AboutUsFirstText {
  font-size: 18px;
  color: #8d8d8d;
  line-height: 1.5;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 300;
  text-align: justify;
}
.AboutUsFirstText span {
  color: #ff9898;
}
.popup-content {
  position: absolute;
  z-index: 999;
  pointer-events: auto;
  top: 75px;
  left: 0px !important;
  right: 0px !important;
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
  /* __________________________Navbar____________________________________ */
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    color: #000 !important;
  }
  .navbar-nav {
    margin: 0 15px;
  }
  .header .inverted {
    padding: 4px 0px;
    margin: 0;
  }
  .navbar {
    margin: 0px 0px;
  }
  /* .navbar-light.inverted .navbar-nav .nav-link a,
  .navbar-light.inverted .navbar-nav .nav-link,
  .dropdown-item a {
    color: #000 !important;
  } */
  .navbar-brand {
    order: 2;
  }

  .header .ourSurvice {
    order: 3;
  }
  .navbar-nav .dropdown-menu {
    padding: 0px;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    position: absolute;
    top: 50px;
    background-color: #FFF;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    color:#000;
    border: 1px solid #FFF;
  }
  .dropdown-toggle {
    position: relative;
  }
  /* .navbar-light .navbar-toggler-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(194deg)
      brightness(103%) contrast(107%);
  } */
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    margin: 0 !important;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  /* .navbar-light.inverted .navbar-toggler-icon {
    filter: invert(0%) sepia(100%) saturate(14%) hue-rotate(327deg)
      brightness(101%) contrast(100%);
  } */
  .navbar-light.inverted .navbar-nav .nav-link a,
  .navbar-light.inverted .navbar-nav .nav-link,
  .dropdown-item a {
    color: #000 !important;
  }

  .navbar-light .navbar-toggler-icon {
    background-image: none;
    height: 100%;
    width: 100%;
    padding-top: 12px;
    font-size: 25px;
  }
  .aboutSlider .slick-prev {
    left: 30px !important;
  }
  .aboutSlider .slick-next {
    left: 80px !important;
  }
  .navbar-toggle {
    display: block;
  }
  .w3-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  #stamenu .menu-vertical {
    overflow-y: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    z-index: 4;
    background: #fff;
    width: 280px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    transition: all 0.5s ease-out 0s;
  }
  #stamenu.active .menu-vertical {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .sidenav .close-nav {
    background-color: #1e1e1e;
    padding: 12px 15px;
    width: 100%;
    display: inline-block;
    color: #fff;
    letter-spacing: 1px;
  }
  .sidenav .menu-responive {
    padding: 0 12px;
  }
  .close-nav a {
    color: #ffffff;
  }
}
@media (min-width: 768px) {
  .FooterVertical .btn {
    display: none;
  }
  .FooterVertical .collapse {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .footerLinkHeading {
    position: relative;
  }
  .FooterVertical .btn {
    text-shadow: none;
    background: none;
    background-image: none;
    background-image: none;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    position: absolute;
    top: -3px;
    right: 0;
    padding: 0;
    width: 100%;
  }
  .FooterVertical .btn::after {
    font-family: "FontAwesome";
    content: "\F067";
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
  .FooterVertical .btn[aria-expanded="true"]::after {
    font-family: "FontAwesome";
    content: "\F068";
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
  .btn-primary:active:focus {
    box-shadow: none;
  }
  .footer-text h3 {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  .footer-text p {
    font-size: 12px;
    line-height: 20px;
  }
  .modal {
    top: -50px;
  }
  .modal {
    top: -50px;
  }
  .SignUpInTitle {
    font-size: 20px !important;
  }
  .form-inputs {
    width: 100%;
  }

  .form-inputs p {
    margin-bottom: -5px;
    font-size: 10px;
  }
  .form-container {
    display: block;
    width: auto;
    padding: 0;
    margin: 12px;
    height: auto;
  }
  .form-content-right {
    padding: 15px;
    border-radius: 10px 10px 10px 10px;
  }
  .form {
    display: block;
    position: unset;
    width: unset;
    height: unset;
    -webkit-transform: unset;
            transform: unset;
  }
  .form h1 {
    font-size: 11px;
    text-align: start;
    width: 100%;
  }

  .form-content-left {
    display: none;
  }
  .form-input-btn {
    width: 100%;
    height: 35px;
    font-size: 14px;
  }
  .form-label {
    font-size: 13px;
    margin-bottom: 2px;
    margin-top: 5px;
  }
}

/* ***************************************************** 1200 - 1410 ***************************************************** */
@media (min-width: 1200px) and (max-width: 1410px) {
  /* _____________________________ Navbar _____________________________ */
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    margin: 0px 8px;
  }
  /* _____________________________ Sign up _____________________________ */

  .form-container {
    margin: 80px auto;
  }
  .aboutSlider .slick-prev {
    left: 30px !important;
  }
  .aboutSlider .slick-next {
    left: 80px !important;
  }
  .otherPageBanner h2 {
    font-size: 17px;
  }
  .otherPageBanner h6 {
    font-size: 48px;
    line-height: 52px;
  }
  .title {
    max-width: 40%;
  }
  /* ________________Banner______________ */
  .faqBanner,
  .planingBanner,
  .contactUsBanner,
  .servicesBanner,
  .portfolioBanner {
    background-size: 100% 100%;
    background-position: center;
    padding: 110px 0px !important ;
  }
}

/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  .otherPageBanner h2 {
    font-size: 17px;
  }
  .otherPageBanner h6 {
    font-size: 42px;
    line-height: 48px;
  }
  .title {
    max-width: 50% !important;
  }
  /* ________________Banner______________ */
  .faqBanner,
  .planingBanner,
  .contactUsBanner,
  .servicesBanner,
  .portfolioBanner {
    background-size: 100% 100%;
    background-position: center;
    padding: 110px 0px !important ;
  }

  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    margin: 0px 1px;
  }
  /* _____________________________ Sign up _____________________________ */

  .form-container {
    width: 900px;
    height: 500px;
  }
  /* _____________________________ Footer _____________________________ */
  .footer-text p {
    font-size: 14px;
    line-height: 28px;
  }
  .Footerbox img {
    margin-bottom: 25px;
  }
  .footer-text h3 {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .aboutSlider .slick-prev {
    left: 30px !important;
  }
  .aboutSlider .slick-next {
    left: 80px !important;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  .otherPageBanner h2 {
    font-size: 17px;
  }
  .otherPageBanner h6 {
    font-size: 42px;
    line-height: 48px;
  }
  .title {
    max-width: 50% !important;
  }
  /* ________________Banner______________ */
  .faqBanner,
  .planingBanner,
  .contactUsBanner,
  .servicesBanner,
  .portfolioBanner {
    background-size: cover;
    background-position: center;
    padding: 150px 0px 80px 0px !important ;
  }
  /* _____________________________ Sign up _____________________________ */
  .form-container {
    width: 700px;
    height: 550px;
    margin: 50px auto;
  }
  .SignUpInTitle {
    font-size: 22px !important;
    margin: 0;
  }
  .form-input-btn {
    margin-top: 0px;
  }
  .form-inputs {
    width: 95%;
  }
  .form-input-btn {
    width: 95%;
    height: 40px;
  }
  /* _____________________________navbar_____________________________ */
  .header .ourSurvice {
    max-height: 40px;
    margin-top: 5px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    margin: 0px 10px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 16px;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 64px;
  }
  /* ________________ Footer ______________ */
  .footer-text p {
    line-height: 30px;
  }
  .footer-text{
    line-height: 30px;
  }
  .footer-text h3 {
    font-size: 17px;
    margin-bottom: 25px;
  }
  .Footerbox img {
  margin-bottom: 10px;
}
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .otherPageBanner h2 {
    font-size: 16px;
  }
  .otherPageBanner h6 {
    font-size: 42px;
    line-height: 40px;
  }
  .title {
    max-width: 90% !important;
  }
  /* ________________Banner______________ */
  .faqBanner,
  .planingBanner,
  .contactUsBanner,
  .servicesBanner,
  .portfolioBanner {
    background-size: cover;
    background-position: center;
    padding: 150px 0px 80px 0px !important ;
  }
  .header .ourSurvice {
    max-height: 35px;
    margin-top: 8px;
  }
  .Footerbox img {
    margin-bottom: 15px;
  }
  .footer {
    padding: 35px 0px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 16px;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 64px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .otherPageBanner h2 {
    font-size: 15px;
  }
  .otherPageBanner h6 {
    font-size: 28px;
    line-height: 40px;
  }
  .title {
    max-width: 90% !important;
  }
  .navbar-light .navbar-toggler-icon {
    font-size: 20px;
    padding-top: 10px;
  }
  /* ________________Banner______________ */
  .faqBanner,
  .planingBanner,
  .contactUsBanner,
  .servicesBanner,
  .portfolioBanner {
    background-size: cover;
    background-position: center;
    padding: 80px 0px 30px 0px !important ;
  }
  .navbar-light .navbar-toggler {
    padding: 0;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 40px;
  }
  .header .inverted {
    padding: 4px 0px;
  }
  .header .inverted .ourSurvice {
    margin-top: 3px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 14px;
  }
  .inner-header {
    padding: 0px 10px;
  }
  .signupBanner {
    padding: 22px;
  }
  .footer {
    padding: 20px 0px;
  }
  .social-icon {
    margin: 0px;
  }
  .footer-text p {
    margin-top: 10px;
  }
  .F-copywrite {
    font-size: 12px;
  }
  .scroll-icon {
    padding: 5px 7px;
  }
  .scroll-icon i {
    font-size: 15px;
    margin: 0px 4px;
  }
  /* ____________Footer____________ */
  .Footerbox img {
    margin-bottom: 0px;
  }
  .social-icon li {
    width: 35px;
    height: 35px;
    padding: 0;
  }
  .social-icon i {
    font-size: 16px;
    margin-top: 4px;
  }
  .footer-text .list-unstyled a {
    margin-top: 1px;
  }
  .footer-text {
    line-height: 26px;
  }
  .footer-text h3 {
    margin-bottom: 10px;
  }
}


.portfolioBanner {
  background-image: url(/static/media/PortfolioBanner.fa83af9b.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 180px 0px;
  background-attachment: fixed;
}
.bloc-tabs {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0 0 5px #cac7c7;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 10px;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: transparent;
  cursor: pointer;
  position: relative;
  outline: none;
  font-size: 20px;
}
.tabs:hover {
  color: #fe0040;
}

.active-tabs {
  background: transparent;
  color: #fe0040;
}

.active-tabs::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(60% + 2px);
  height: 5px;
  background: #fe0040;
  border-radius: 50px;
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 10px 0px 0px 0px;
  width: 100%;
  height: 100%;
  display: none;
}
.portfolio .card {
  border: none;
  box-shadow: 0 0 10px #e2e1e1;
  margin-bottom: 25px;
  border-radius: 10px;
}
.portfolio .card .card-img-top {
  border-radius: 10px 10px 0px 0px;
}
.portfolio .card-title {
  font-size: 22px;
  color: #fe0040;
  cursor: pointer;
transition: 0.4s ease;
}
.portfolio .card-title:hover {
color: #000;
}
.content p {
  width: 100%;
  height: 100%;
  line-height: 28px;
  font-size: 17px;
}
.active-content {
  display: block;
}
.portfolio .beffect .bimg::after {
border-radius: 10px;
}
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {

  .tabs {
    font-size: 18px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {

  .content p {
    line-height: 20px;
    font-size: 16px;
  }
  .tabs {
    padding: 8px;
    font-size: 18px;
  }
  .card-title {
    font-size: 18px;
  }
  .active-tabs::before {
    height: 4px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {

  .content p {
    line-height: 20px;
    font-size: 14px;
  }
  .card-title {
    font-size: 17px;
  }
  .bloc-tabs {
    flex-wrap: wrap;
  }
  .tabs {
    font-size: 17px;
    padding: 7px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {

  .bloc-tabs {
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .tabs {
    padding: 8px;
    font-size: 15px;
  }
  .active-tabs::before {
    height: 3px;
  }
  .content {
    padding: 0px;
  }
  .card-title {
    font-size: 18px;
    margin: 0;
  }
  .content p {
    line-height: 20px;
    font-size: 14px;
  }
}

.contactUsBanner {
  background-image: url(/static/media/ContactUsBackgroundBanner.a24d254d.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 180px 0px;
  background-attachment: fixed;
}
.contactContain{
  margin: 80px 0px;

}
.contactIconBgShadow {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px 55px;
  position: relative;
  transition: 0.4s ease;
}
.contactIconBgShadow:hover {
  box-shadow: 0 0 10px #e2e1e1;
}
.contactIconBgShadow i {
  font-size: 60px;
  color: #fe0040;
}
.contactIconBgShadow h2 {
  font-size: 24px;
  margin: 15px 0px;
}
.contactIconBgShadow h6 {
  color: #666666;
  cursor: pointer;
}
.iframeContactUs {
  margin-top: 80px;
  display:block !important;
}
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {

  .contactIconBgShadow h2 {
    font-size: 22px;
  }
  .iframeContactUs {
    margin-top: 60px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {

.contactIconBgShadow{
  padding: 20px 15px;
}
.contactIconBgShadow i {
    font-size: 34px;
}
.contactIconBgShadow h2 {
    font-size: 22px;
}
.contactIconBgShadow p{
  font-size: 16px;
}
.contactContain {
  margin: 60px 0px;
}
.iframeContactUs {
  margin-top: 50px;
}
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {

  .contactIconBgShadow h2 {
    font-size: 19px;
    margin: 10px 0px;
}
.contactIconBgShadow  p{
  font-size: 15px;
}
  .contactContain{
    margin: 50px 0px;
  }
  .contactIconBgShadow{
    padding: 3px;
  }
  .contactIconBgShadow i{
    font-size: 30px;
  }
  .iframeContactUs {
    margin-top: 40px;
  }

 
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {


.contactContain{
  margin: 40px 0px;
}
  .contactIconBgShadow {
    margin: 10px 0px;
  padding:10px 10px;
  }
  .contactIconBgShadow i {
    font-size: 38px;
  }
  .contactIconBgShadow h2 {
    font-size: 18px;
    margin: 10px 0px;
}
.contactIconBgShadow p{
  margin: 0px;
  font-size: 14px;
}
.iframeContactUs {
  margin-top: 30px;
}
}

.mainBannerDiv {
  position: relative;
}
.bannerContain {
  position: absolute;
  top: 30%;
  left: 8%;
  max-width: 40%;
}
.bannerContain h6 {
  color: #fe0040;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}
.bannerContain h1 {
  font-size: 60px;
  color: #000;
  font-weight: 600;
  line-height: 90px;
}
.bannerContain h5 {
  font-size: 18px;
  color: #000;
  line-height: 35px;
}
.bannerBtn {
  margin-top: 50px;
}
.bannerBtn .homeBannerBtn {
  margin-right: 30px;
}
.homeBannerBtn {
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
}
.ourSurvice {
  background-color: #fe0040;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
  border-radius: 6px;
  padding: 10px 20px;
}
.ourSurvice a,
.ourSurvice {
  text-decoration: none;
  color: #fff;
}
.ourSurvice:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.ourSurvice::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  background: #fe0040;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.ourSurvice:hover {
  background-color: #ff9898;
  color: #fff;
}

.ourContact {
  background-color: #fff;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
  border-radius: 6px;
  padding: 10px 20px;
  margin-left: 30px;
}
.ourContact a {
  text-decoration: none;
  color: #000;
}
.ourContact:hover a {
  color: white;
}
.ourContact:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.ourContact::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  background-color: #fff;

  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.ourContact:hover {
  background-color: rgb(107, 102, 102);
  color: #fff;
}
/* _________________________________________Our Feature________________________________________ */
.ourFeatureImg {
  background-image: url(/static/media/features.7af9785e.jpg);
  padding: 60px 0;
  background-attachment: fixed;
  overflow: hidden;
}
.ourFeature {
  padding: 80px 0px;
}
.ourFeature h2 {
  max-width: 50%;
  margin: 0 auto;
}
.mainFeaturesCard {
  margin-top: 44px;
  margin-bottom: 0px;
  text-align: center;
}
.featuresCard {
  background-color: #ffffff;
  box-shadow: 0 0 10px #e2e1e1;
  border-radius: 10px;
  padding: 44px;
  position: relative;
  transition: 0.4s ease;
  text-align: left;
  cursor: pointer;
}
.featuresCardCircleArea{
  display: flex;
  justify-content: center;
  padding: 10px;
}
.featuresCardCircle {
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 10px #e2e1e1;
}
.featuresCardCircle img {
  margin: 0 auto;
  transition: 0.4s ease;
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
          filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
}
.featuresCardContain {
  text-align: left;
}
.featuresCardContain h3 {
  font-size: 24px;
  color: #000000;
  padding: 30px 0px 20px 0px;
  margin: 0px;
  transition: 0.3s ease;
}
.featuresCard:hover .featuresCardContain h3 {
  color: #fe0040;
}

.featuresCard:hover .featuresCardCircle img {
  -webkit-filter: none;
          filter: none;
  -webkit-transform: rotateY(3.142rad);
  transform: rotateY(3.142rad);
}
.featuresCardContain h6 {
  color: #666666;
  font-size: 16px;
  font-weight: 300;
  line-height: 35px;
}
.mainFeaturesCard button {
  margin-top: 40px;
  margin-bottom: 0px;
}

.details[open] #open {
  display: none;
}
.details #close {
  display: none;
}
.details[open] #close {
  display: block;
}
::-webkit-.details-marker {
  display: none;
}
.details {
  display: inline;
}
.details {
  position: relative;
}
.details .summary {
  display: block;
  cursor: pointer;
  padding-top: 10px;
  font-size: 18px;
  color: #000;
  transition: 0.3s ease;
}
.details .summary:hover {
  color: #fe0040;
}

.details .summary:focus {
  outline: none;
}
.details[open] {
  display: block;
  padding-bottom: 25px;
  padding-top: 10px;
  -webkit-animation: open 0.2s linear;
          animation: open 0.2s linear;
}
.details[open] .summary {
  position: absolute;
  bottom: 0;
  left: 0;
}
  /* _________________________________________ Choose Us ________________________________________ */
.chooseBanneImg {
  background-image: url(/static/media/ChooseBanner.7cf901b5.jpg);
  padding: 60px 0;
  background-attachment: fixed;
  overflow: hidden;
}
.chooseBanneImg h2 {
  color: #fff;
}
.chooseBanneImg .paragraph {
  color: #d8d2d2;
  margin-bottom: 50px;
}
.chooseBox {
  background-color: #fff;
  transition: 0.4s ease;
  padding: 20px;
  cursor: pointer;
  margin: 10px 0px;
  border-radius: 5%;
}
.chooseBox img {
  -webkit-filter: none;
          filter: none;
  transition: 0.4s ease;
}
.chooseBox h3 {
  font-size: 40px;
  margin: 10px 0px;
  color: #000;
  transition: 0.4s ease;
  font-weight: 500 !important;
}
.chooseBox h3 span{
  font-weight: 500 ;
}
.chooseBanner h5 {
  font-size: 18px;
  color: #fff;
  line-height: 35px;
  font-weight: 300;
  margin: 20px 0px 45px 0px;
  transition: 0.4s ease;
}
.chooseBox h6 {
  font-size: 20px;
  color: #707070;
  margin: 0;
  transition: 0.4s ease;
}
.chooseBox:hover {
  background-color: #FF0000;
}
.chooseBox:hover img {
  -webkit-transform: rotateY(3.142rad);
          transform: rotateY(3.142rad);
}
.chooseBox:hover h3,
.chooseBox:hover h6 {
  color: #fff;
}
/* __________________________________________________Year of experience______________________________________________________ */
.yearOfExperience {
  background-image: url(/static/media/ExperienceBanner.9189db85.png);
  min-width: 100%;
  position: relative;
  padding: 40px 0px;
}

.scrollbar {
  left: 0;
}
.scrollbar h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
}
.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0px 20px 0px;
  height: 12px;
  width: 500px;
}

.progress-done {
  background: #fe0040;
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
/* _______________________________________about Us ________________________________________________ */
.mainAboutUs {
  margin: 70px 0px;
  overflow: hidden;
}
.aboutUs .titleFont {
  margin-top: 0px;
}
.aboutUs {
  margin-left: 50px;
}
.aboutIconMainDiv {
  display: inline-block;
  vertical-align: middle;
}
.aboutUs h5 {
  font-size: 18px;
  color: #fff;
  line-height: 35px;
  font-weight: 300;
  margin: 20px 0px 45px 0px;
}
.aboutIconCircle {
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 10px #fff;
  transition: 0.4s ease;
  cursor: pointer;
}
.aboutIconCircle:hover {
  background-color: #fe0040;
}
.aboutIconCircle img {
  margin: 0 auto;
  transition: 0.4s ease;
}
.aboutIconCircle:hover img {
  -webkit-filter: invert(96%) sepia(98%) saturate(10%) hue-rotate(157deg)
    brightness(200%) contrast(103%);
          filter: invert(96%) sepia(98%) saturate(10%) hue-rotate(157deg)
    brightness(200%) contrast(103%);
  -webkit-transform: rotateY(3.142rad);
          transform: rotateY(3.142rad);
}
.aboutUsIconSideText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 35px;
}
.aboutUsIconSideText h6 {
  font-size: 25px;
  cursor: pointer;
  color: #fff;
}
.aboutUsIconSideText h6:hover {
  color: #fe0040;
}
.aboutUsIconSideText p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  line-height: 30px;
}
.aboutIconMainDivFloat {
  display: flex;
  margin: 25px 0px;
}
.aboutSlider {
  max-width: 100%;
  margin: 0 auto;
}
.aboutSlider .slick-list {
  padding-left: 0px !important;
}
.aboutSlider img {
  border-radius: 10px;
  display: inline-block;
}
.aboutSlider .slick-next {
  top: auto;
  bottom: 0;
  left: 65px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
}
.aboutSlider .slick-prev {
  top: auto;
  bottom: 0;
  left: 15px;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
}
.aboutSlider .slick-next:hover,
.aboutSlider .slick-prev:hover {
  background-color: #ffffff;
}
.aboutSlider .slick-next::before {
  content: "\F061";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.aboutSlider .slick-prev::before {
  content: "\F060";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.aboutSlider .slick-arrow:hover::before {
  color: black;
}
/* _____________________________________________________ourBestServicesBanner_____________________________________________ */
.ourBestServicesBanner {
  background-image: url(/static/media/OurBestSerBanner.007f7692.png);
  width: 100%;
  padding: 80px 0px;
}
.ourBestServices .titleFont {
  max-width: 65%;
  margin: 0 auto;
}
.ourBestServicesRow {
  padding-top: 40px;
}
.ourBestServices .card-body {
  padding: 25px 35px;
}
.ourBestServices .card {
  border: none;
  box-shadow: 0 0 10px #d3d3d3;
  border-radius: 10px;
}
.ourBestServices .card img {
  border-radius: 10px 10px 0px 0px;
}
.ourBestServices .card .card-title {
  font-size: 23px;
  font-weight: 600;
  cursor: pointer;
}
.ourBestServices .card .card-text {
  font-size: 16px;
  color: #666666;
  padding: 12px 0px;
  line-height: 30px;
}
/* ________________________________________________ourPricing___________________________________________ */
.ourPricing {
  padding: 80px 0px;
}
.ourPricingCard {
  margin-top: 40px;
}

.ourPricingCard .featuresCard {
  box-shadow: none;
  border: 1px solid #e2e1e1;
  transition: 0.5s ease !important;
}
.ourPricingCard .featuresCard:hover {
  box-shadow: 0 0 10px #e2e1e1;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.ourPricingCard .featuresCard h5 {
  font-size: 30px;
  color: #fe0040;
  font-weight: 600;
  line-height: 65px;
}
.ourPricingCard .featuresCard h6 {
  font-size: 20px;
  font-weight: 400;
  line-height: 10px;
}
.ourPricingCard .featuresCard h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 55px;
}
.ourPricingCard .featuresCard span {
  font-size: 25px;
  color: #666666;
  text-decoration: line-through;
  margin-left: 15px;
}
.ourPricingCard .featuresCard ul li {
  font-size: 18px;
  color: #666666;
  line-height: 50px;
}
.slick-slider [class*="col-"] {
  padding: 0 15px;
}
/* __________________________________________________________ourTeam___________________________________________ */
.beffect .bimg {
  display: block;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
}
.beffect img {
  max-width: 100%;
  width: 100%;
  transition: all 0.5s;
}
.beffect .bimg::after {
  background: rgba(0, 0, 0, 0.5);
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  -webkit-transition: all 0.4s ease-in-out 0s;
  right: 0;
  top: 0;
  border-radius: 5px;
}
.beffect .bimg:hover::after {
  width: 100%;
  height: 100%;
  right: auto;
  left: 0;
}
.ourTeamMain {
  margin: 40px 0px 20px 0px;
}
.ourTeamMain .card {
  box-shadow: none;
  border: 1px solid #e2e1e1;
  border-radius: 10px;
}
.ourTeamMain img {
  border-radius: 10px 10px 0px 0px;
}
.ourTeamMain .beffect .bimg::after {
  border-radius: 10px 10px 0px 0px;
}
.ourTeamMain .slick-next {
  display: block;
  bottom: 0;
  top: -50px;
  right: 20px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourTeamMain .slick-prev {
  display: block;
  bottom: 0;
  top: -50px;
  right: 70px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourTeamMain .slick-next:hover,
.ourTeamMain .slick-prev:hover {
  background-color: #ffffff;
}
.ourTeamMain .slick-next::before {
  content: "\F061";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourTeamMain .slick-prev::before {
  content: "\F060";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourTeamMain .slick-arrow:hover::before {
  color: black;
}
.ourTeamMain h2 {
  font-size: 18px;
}
.ourTeamMain h6 {
  font-size: 16px;
}

.ourTeamImgBox {
  position: relative;
  text-align: center;
  cursor: pointer;
}
.ourTeamIcon {
  position: absolute;
  bottom: -20px;
  right: 0;
  left: 0;
  margin: 0 auto;
  transition: 0.5s ease;
  opacity: 0;
}

.ourTeamCard:hover .ourTeamIcon {
  bottom: 40%;
  opacity: 1;
  transition: 0.5s ease;
}
.ourTeamIcon li {
  width: 35px;
  font-size: 18px;
  background-color: white;
  text-align: center;
  margin-right: 5px;
  margin-left: 5px;
  text-decoration: none;
  color: black;
  border-radius: 5px;
  top: 50%;
}
.ourTeamIcon li a {
  color: #000;
  padding: 4px;
}

.ourTeamIcon li a:hover {
  color: white;
  background-color: #fe0040;
  transition: 0.4s ease;
  border-radius: 5px;
}
.ourTeamMain .card-title {
  color: #000000;
  font-size: 18px;
  text-align: center;
  line-height: 35px;
  transition: 0.4s ease;
}
.ourTeamMain .card-title:hover {
  color: #fe0040;
}
.ourTeamMain .card-subtitle {
  font-size: 16px;
  color: #666666;
  text-align: center;
  line-height: 30px;
}
/* _____________________________________________________________________ourTestimonialCard______________________ */
.ourTestimonialCol {
  padding: 0px;
}
.ourTestimonialMain {
  padding: 80px 0px;
}
.ourTestimonialCard {
  margin-top: 40px;
}
.ourTestimonialCard .featuresCard {
  padding: 20px 50px;
  box-shadow: none;
  border: 1px solid #e2e1e1;
  margin-top: 50px;
  margin-bottom: 15px;
}
.ourTestimonialCard .featuresCard:hover {
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 8%);
  border: none;
}
.ourTestimonialCard .slick-slider {
  padding: 0px;
}
.testimonialImg {
  position: absolute;
  top: -50px;
  left: 0px;
  right: 0;
  border-radius: 50px;
}
.testimonialQuote {
  margin: 45px 0px 10px 0px;
}
.testimonialCard p {
  font-size: 14px;
  color: #666666;
  line-height: 27px;
}
.testimonialCard h6 {
  font-size: 15px;
  color: #000;
}
.testimonialCard h2 {
  font-size: 16px;
  color: #fe0040;
}
.ourTestimonialMain .slick-next {
  display: block;
  bottom: 0;
  top: -50px;
  right: 20px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourTestimonialMain .slick-prev {
  display: block;
  bottom: 0;
  top: -50px;
  right: 70px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourTestimonialMain .slick-next:hover,
.ourTestimonialMain .slick-prev:hover {
  background-color: #ffffff;
}
.ourTestimonialMain .slick-next::before {
  content: "\F061";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourTestimonialMain .slick-prev::before {
  content: "\F060";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourTestimonialMain .slick-arrow:hover::before {
  color: black;
}
/* ________________________________________________Contact Us______________________________________ */
.contactUs {
  padding: 60px 0px;
}
.aboutUsBannerImg {
  background-image: url(/static/media/ContactUsBanner.ec408ab2.png);
}
.quickContact {
  max-width: 85%;
  float: right;
  text-align: justify;
  line-height: 35px;
}
.quickContact h2 {
  font-size: 40px;
  margin-bottom: 30px;
}
.quickContact p,
.quickContact span {
  color: #666666;
}
.formContactus {
  max-width: 100%;
}
.contactUs .row.needs-validation {
  align-items: center;
  --bs-gutter-x: 0;
}
.contactUs .row.needs-validation [class*='col-'] {
  padding: 0 5px;
  
}
.contactUsIconContain {
  max-width: 70%;
  margin-bottom: 10px;
}
.contactUsIconContain {
  cursor: pointer;
}
.contactUsIconContain span {
  display: inline-block;
  max-width: 363px;
  vertical-align: top;
  margin-left: 20px;
}
.contactUsIconContain:hover i {
  color: #fe0040;
}

.formContactus .form-control {
  padding: 12px;
}
.formContactus input,
.formContactus textarea {
  box-shadow: none;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  margin: 10px 0px;
}
.formContactus textarea {
  min-height: 150px;
}
.formContactus .form-control:focus {
  box-shadow: none;
}
/* _____________________________________________________ourBlog__________________________________ */
.ourBlog {
  margin: 80px 0px 60px 0px;
}
.ourBlogCard {
  margin-top: 40px;
}
.ourBlogCard .card-body {
  padding: 30px;
}
.ourBlogContain .ourBlogContainIcon {
  float: right;
}
.ourBlogContainIcon i {
  color: #fe0040;
  margin-right: 5px;
}
.ourBlogCard .card-title {
  font-size: 20px;
  line-height: 35px;
  color: #000000;
  transition: 0.4s ease;
  cursor: pointer;
}
.ourBlogCard .card-title:hover {
  color: #fe0040;
}
.ourBlogCard .card-text {
  font-size: 14px;
  line-height: 30px;
  color: #666666;
}
.ourBlogCard .card {
  border-radius: 10px;
}
.slick-slider [class*="col-"] {
  padding: 0 15px;
}
.ourBlogCard .slick-slide img {
  display: block;
  border-radius: 10px 10px 0px 0px;
}

.ourBlog .slick-next {
  display: block;
  bottom: 0;
  top: -50px;
  right: 20px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourBlog .slick-prev {
  display: block;
  bottom: 0;
  top: -50px;
  right: 70px;
  left: auto;
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  background-color: #fe0040;
  opacity: unset;
  transition: 0.5s ease;
  box-shadow: 0 0 10px #e2e1e1;
}
.ourBlog .slick-next:hover,
.ourBlog .slick-prev:hover {
  background-color: #ffffff;
}
.ourBlog .slick-next::before {
  content: "\F061";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourBlog .slick-prev::before {
  content: "\F060";
  font-family: FontAwesome;
  font-size: 25px;
  opacity: 1;
  transition: 0.5s ease;
}
.ourBlog .slick-arrow:hover::before {
  color: black;
}

/* ______________________________________________________________Brand Logo_______________________________________________ */
.brandLogo .slick-prev,
.brandLogo .slick-next {
  display: none !important;
}
.startedToday {
  box-shadow: 0 -1px 7px -5px #333333de;
  padding: 25px 0px;
  margin-top: 60px;
}

.startedTodayMainDivFloat .row {
  align-items: center;
}
.startedTodayMainDiv {
  display: inline-block;
}
.startedTodaySideText {
  text-align: right;
}
.startedTodayMainDiv h6 {
  font-size: 18px;
}
.startedTodayMainDiv p {
  font-size: 30px;
  font-weight: 600;
  max-width: 79%;
  margin: 0;
}

/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {
  /* _____________________________navbar_____________________________ */
  /* _____________________________banner_____________________________ */
  .bannerContain {
    top: 25%;
    max-width: 45%;
  }
  .bannerContain h1 {
    font-size: 40px;
    line-height: 60px;
  }
  .bannerContain h5 {
    font-size: 16px;
    line-height: 30px;
  }
  .bannerBtn {
    margin-top: 25px;
  }
  /*_____________________________title_____________________________ */
  .titleFont h2 {
    font-size: 45px;
  }

  /*_____________________________features_____________________________ */
  .featuresCard {
    padding: 30px;
  }
  .featuresCardContain h6 {
    font-size: 16px;
    line-height: 30px;
  }
  .featuresCardContain h3 {
    font-size: 22px;
    padding: 20px 0px 10px 0px;
  }
  /* _____________________________aboutUs_____________________________ */
  .aboutSlider {
    margin: 0 -15px;
  }
  .aboutSlider img {
    padding: 0 15px;
  }
  .aboutUs h5 {
    font-size: 16px;
    line-height: 30px;
    margin: 10px 0px;
  }
  .aboutUsIconSideText p {
    font-size: 16px;
  }
  .aboutIconMainDivFloat {
    margin: 15px 0px;
  }

  /* _____________________________Our Best Services_____________________________ */
  .ourBestServices .card .card-title {
    font-size: 22px;
  }
  .ourTestimonialCard .featuresCard {
    padding: 15px 30px;
  }
  /* _____________________________Let's Contact Us_____________________________ */
  .quickContact h2 {
    font-size: 38px;
    margin-bottom: 20px;
  }
  /* _____________________________Our Blog_____________________________ */
  .ourBlogCard .card-body {
    padding: 25px;
  }

  /* _____________________________STARTED_____________________________ */
  .startedTodayMainDiv p {
    font-size: 25px;
  }
}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  /* _____________________________navbar_____________________________ */
  .navbar-light .navbar-nav .nav-link a {
    font-size: 15px;
    margin: 0px 10px;
  }

  /* _____________________________banner_____________________________ */
  .bannerContain h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .bannerContain {
    top: 26%;
    left: 4%;
  }
  .bannerContain h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .bannerContain h5 {
    font-size: 15px;
    line-height: 22px;
  }
  .bannerBtn {
    margin-top: 25px;
  }
  .homeBannerBtn {
    min-width: 135px;
    min-height: 43px;
  }
  /*_____________________________title_____________________________ */
  .titleFont h2 {
    font-size: 40px;
  }

  /*_____________________________features_____________________________ */
  .ourFeature {
    padding: 60px 0px;
  }
  .featuresCard {
    padding: 25px;
  }
  .featuresCardCircle {
    width: 90px;
    height: 90px;
  }
  .featuresCardContain h6 {
    font-size: 16px;
    line-height: 27px;
  }
  .featuresCardContain h3 {
    font-size: 20px;
    padding: 17px 0px 10px 0px;
  }
  .details .summary {
    padding-top: 0px;
    font-size: 17px;
  }
  /* _____________________________Year of experience_____________________________ */
  .scrollbar h3 {
    font-size: 18px;
    margin: 30px 0px 0px 0px;
  }
  .progress {
    width: 380px;
  }
  /* _____________________________aboutUs_____________________________ */
  .aboutSlider {
    margin: 0 -15px;
  }
  .aboutSlider img {
    padding: 0 15px;
  }
  .mainAboutUs {
    margin: 60px 0px;
  }
  .aboutUs {
    margin-left: 0px;
  }
  .aboutIconCircle {
    width: 80px;
    height: 80px;
  }
  .aboutUsIconSideText h6 {
    font-size: 22px;
  }
  .aboutUs h5 {
    font-size: 16px;
    line-height: 30px;
    margin: 10px 0px;
  }
  .aboutUsIconSideText p {
    font-size: 16px;
  }
  .aboutIconMainDivFloat {
    margin: 15px 0px;
  }

  /* _____________________________Our Best Services_____________________________ */
  .ourBestServicesBanner {
    padding: 60px 0px;
  }
  .ourBestServicesRow {
    padding-top: 30px;
  }
  .ourBestServices .card-body {
    padding: 20px 22px;
  }
  .ourBestServices .card .card-title {
    font-size: 19px;
  }
  .ourBestServices .card .card-text {
    font-size: 15px;
    padding: 0px 0px;
    line-height: 28px;
  }
  .ourTestimonialCard .featuresCard {
    padding: 15px 15px;
  }
  /* _____________________________ Our Pricing _____________________________ */
  .ourPricing {
    padding: 60px 0px;
  }
  .ourPricingCard .featuresCard h2 {
    font-size: 38px;
  }
  .ourPricingCard .featuresCard span {
    font-size: 22px;
    margin-left: 10px;
  }
  .ourPricingCard .featuresCard ul li {
    font-size: 17px;
    line-height: 40px;
  }

  /* _____________________________ourTeam_____________________________ */
  .ourTeamMain .card-subtitle {
    font-size: 15px;
    line-height: 10px;
  }

  /* _____________________________Our Testimonial_____________________________ */

  .ourTestimonialCard .featuresCard {
    margin: 50px 0px 0px 0px;
  }
  .ourTestimonialMain {
    padding: 60px 0px;
  }
  .testimonialCard p {
    font-size: 14px;
    line-height: 25px;
  }
  .testimonialCard h6 {
    font-size: 16px;
  }

  /* _____________________________Let's Contact Us_____________________________ */
  .formContactus input,
  .formContactus textarea {
    margin: 0px;
  }
  .quickContact {
    max-width: 95%;
    line-height: 31px;
  }
  .quickContact h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  /* _____________________________Our Blog_____________________________ */
  .ourBlog {
    margin: 60px 0px;
  }
  .ourBlogCard .card-body {
    padding: 20px;
  }
  .ourBlogCard .card-title {
    font-size: 17px;
    line-height: 25px;
  }
  .ourBlogCard .card-text {
    line-height: 25px;
  }

  /* _____________________________STARTED_____________________________ */
  .startedTodayMainDiv p {
    font-size: 23px;
  }
  .startedToday {
    margin-top: 60px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  /* _____________________________banner_____________________________ */

  .bannerContain h6 {
    font-size: 16px;
    line-height: 20px;
  }
  .bannerContain {
    top: 33%;
    left: 4%;
    max-width: 50%;
  }
  .bannerContain h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .bannerContain h5 {
    display: none;
  }
  .bannerBtn {
    margin-top: 25px;
  }

  .homeBannerBtn {
    min-width: 115px;
    min-height: 40px;
  }
  .bannerBtn .homeBannerBtn {
    margin-right: 20px;
  }
  .ourSurvice,
  .ourContact {
    padding: 7px 17px;
  }
  /*_____________________________title_____________________________ */
  .titleFont h2 {
    font-size: 34px;
  }

  /*_____________________________features_____________________________ */
  .ourFeature {
    padding: 50px 0px;
  }
  .featuresCard {
    padding: 15px;
  }
  .featuresCardCircle {
    width: 90px;
    height: 90px;
  }
  .featuresCardContain h6 {
    font-size: 14px;
    line-height: 22px;
  }
  .featuresCardContain h3 {
    font-size: 20px;
    padding: 17px 0px 10px 0px;
  }
  .details .summary {
    padding-top: 0px;
    font-size: 16px;
  }
  .mainFeaturesCard button {
    margin-top: 30px;
  }
  /* _____________________________Year of experience_____________________________ */
  .scrollbar h3 {
    font-size: 18px;
    margin: 30px 0px 0px 0px;
  }
  .progress {
    width: 320px;
  }
  /* _____________________________aboutUs_____________________________ */

  .aboutSlider img {
    padding: 0 15px;
  }
  .aboutUs {
    margin-left: 0px;
    margin-top: 20px;
  }
  .aboutIconCircle {
    width: 80px;
    height: 80px;
  }
  .aboutUsIconSideText h6 {
    font-size: 22px;
  }
  .aboutUs h5 {
    font-size: 16px;
    line-height: 30px;
    margin: 10px 0px;
  }
  .aboutUsIconSideText p {
    font-size: 16px;
    line-height: 25px;
  }
  .aboutIconMainDivFloat {
    margin: 15px 0px;
  }

  /* _____________________________Our Best Services_____________________________ */
  .ourBestServicesBanner {
    padding: 50px 0px;
  }
  .ourBestServicesRow {
    padding-top: 30px;
  }
  .ourBestServices .card-body {
    padding: 10px 13px;
  }
  .ourBestServices .card .card-title {
    font-size: 20px;
  }
  .ourBestServices .card .card-text {
    font-size: 15px;
    padding: 0px 0px;
    line-height: 22px;
    margin: 10px 0px;
  }
  .ourTestimonialCard .featuresCard {
    padding: 15px 15px;
  }
  /* _____________________________ Our Pricing _____________________________ */
  .ourPricing {
    padding: 50px 0px;
  }
  .ourPricingCard .featuresCard h2 {
    font-size: 26px;
    line-height: 40px;
  }
  .ourPricingCard .featuresCard span {
    font-size: 20px;
    margin-left: 8px;
  }
  .ourPricingCard .featuresCard h5 {
    font-size: 26px;
    line-height: 40px;
  }
  .ourPricingCard .featuresCard h6 {
    font-size: 16px;
  }
  .ourPricingCard .featuresCard ul li {
    font-size: 16px;
    line-height: 35px;
  }

  /* _____________________________ourTeamMain_____________________________ */
  .ourTeamMain .card-title {
    line-height: 15px;
  }
  .ourTeamMain .card-subtitle {
    font-size: 15px;
    line-height: 20px;
  }
  .ourTeamMain .card-body {
    padding: 15px;
  }

  /* _____________________________Our Testimonial_____________________________ */

  .ourTestimonialCard .featuresCard {
    margin: 50px 0px 0px 0px;
  }
  .ourTestimonialMain {
    padding: 50px 0px;
  }
  .testimonialCard p {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 5px;
  }
  .testimonialCard h6 {
    font-size: 15px;
    margin-bottom: 1px;
  }
  .testimonialCard h2 {
    font-size: 15px;
  }

  /* _____________________________Let's Contact Us_____________________________ */
  .contactUs {
    padding: 50px 0px;
  }
  .formContactus input,
  .formContactus textarea {
    margin: 0px;
  }
  .quickContact {
    max-width: 95%;
    line-height: 31px;
  }
  .quickContact h2 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .quickContact p {
    font-size: 15px;
    line-height: 22px;
  }
  .contactUsIconContain span {
    font-size: 15px;
    line-height: 22px;
    max-width: 190px;
  }
  /* _____________________________Our Blog_____________________________ */
  .ourBlog {
    margin: 50px 0px;
  }
  .ourBlogContain .ourBlogContainIcon {
    font-size: 12px;
    margin-top: 3px;
  }
  .ourBlogContain span {
    font-size: 12px;
  }
  .ourBlogContainIcon i {
    font-size: 10px;
  }
  .ourBlogCard .card-body {
    padding: 15px;
  }
  .ourBlogCard .card-title {
    font-size: 16px;
    line-height: 20px;
  }
  .ourBlogContain hr {
    margin: 10px 0px;
  }
  .ourBlogCard .card-text {
    line-height: 21px;
    font-size: 13px;
    text-align: justify;
  }

  /* _____________________________STARTED_____________________________ */
  .startedTodayMainDiv p {
    font-size: 20px;
  }
  .startedToday {
    margin-top: 50px;
    padding: 15px 0px;
  }
  .startedTodayMainDiv h6 {
    font-size: 15px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  /* _____________________________navbar_____________________________ */

  /* _____________________________banner_____________________________ */
  .bannerContain h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .bannerContain {
    top: 35%;
    left: 4%;
    max-width: 50%;
  }
  .bannerContain h1 {
    font-size: 23px;
    line-height: 30px;
  }
  .bannerContain h5 {
    display: none;
  }
  .bannerBtn {
    margin-top: 15px;
  }
  .homeBannerBtn {
    min-width: 100px;
    min-height: 38px;
    font-size: 14px;
  }
  .bannerBtn .homeBannerBtn {
    margin-right: 15px;
  }
  .ourSurvice,
  .ourContact {
    padding: 6px 14px;
    font-size: 14px;
  }
  .ourContact {
    margin-left: 15px;
  }
  /*_____________________________title_____________________________ */
  .titleFont h2 {
    font-size: 26px;
  }
  .titleFont h3 {
    font-size: 18px;
  }
  /*_____________________________features_____________________________ */
  .ourFeature {
    padding: 50px 0px;
  }
  .featuresCard {
    padding: 15px;
  }
  .featuresCardCircle {
    width: 80px;
    height: 80px;
  }
  .featuresCardContain h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .featuresCardContain h3 {
    font-size: 16px;
    padding: 12px 0px 4px 0px;
  }
  .details .summary {
    padding-top: 0px;
    font-size: 15px;
  }
  .mainFeaturesCard button {
    margin-top: 30px;
  }
  .mainFeaturesCard button {
    margin-top: 25px;
  }
  /* _____________________________Year of experience_____________________________ */
  .scrollbar h3 {
    font-size: 14px;
    margin: 10px 0px -10px 0px;
  }
  .progress {
    width: 250px;
  }
  /* _____________________________aboutUs_____________________________ */
  .aboutSliderDiv {
    margin: 0 -15px;
  }
  .aboutSliderDiv img {
    padding: 0 15px !important;
  }
  .aboutUs {
    margin-left: 0px;
    margin-top: 20px;
  }
  .mainAboutUs {
    margin: 50px 0px;
  }
  .aboutIconCircle {
    width: 80px;
    height: 80px;
  }
  .aboutUsIconSideText h6 {
    font-size: 18px;
  }
  .aboutUs h5 {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0px;
  }
  .aboutUsIconSideText p {
    font-size: 14px;
    line-height: 20px;
  }
  .aboutIconMainDivFloat {
    margin: 15px 0px;
  }

  .aboutSliderDiv {
    margin: 0 -15px;
  }
  .aboutSliderDiv img {
    padding: 0 15px !important;
  }
  /* _____________________________Our Best Services_____________________________ */
  .ourBestServicesBanner {
    padding: 50px 0px;
  }
  .ourBestServicesRow {
    padding-top: 20px;
  }
  .ourBestServices .card-body {
    padding: 10px 13px;
  }
  .ourBestServices .card .card-title {
    font-size: 16px;
  }
  .ourBestServices .card .card-text {
    font-size: 13px;
    padding: 0px 0px;
    line-height: 20px;
    margin: 5px 0px;
  }
  .ourTestimonialCard .featuresCard {
    padding: 15px 15px;
  }
  /* _____________________________ Our Pricing _____________________________ */
  .ourPricing {
    padding: 50px 0px;
  }
  .ourPricing .featuresCard {
    padding: 15px;
  }
  .ourPricingCard {
    margin-top: 20px;
  }
  .ourPricingCard .featuresCard h2 {
    font-size: 20px;
    line-height: 40px;
  }
  .ourPricingCard .featuresCard span {
    font-size: 16px;
    margin-left: 5px;
  }
  .ourPricingCard .featuresCard h5 {
    font-size: 22px;
    line-height: 25px;
  }
  .ourPricingCard .featuresCard h6 {
    font-size: 16px;
  }
  .ourPricingCard .featuresCard ul li {
    font-size: 14px;
    line-height: 27px;
  }

  /* _____________________________ourTeamMain_____________________________ */
  .ourTeamMain .card-title {
    line-height: 15px;
    font-size: 16px;
  }
  .ourTeamMain .card-subtitle {
    font-size: 14px;
    line-height: 20px;
  }
  .ourTeamMain .card-body {
    padding: 15px;
  }
  .ourTeamMain {
    margin: 20px 0px 20px 0px;
  }

  /* _____________________________Our Testimonial_____________________________ */

  .ourTestimonialCard .featuresCard {
    padding: 30px 0px 50px 0px;
  }
  .ourTestimonialCard {
    margin-top: 20px;
  }
  .ourTestimonialMain {
    padding: 50px 0px;
  }
  .testimonialCard p {
    font-size: 14px;
    line-height: 25px;
  }
  .testimonialCard h6 {
    font-size: 16px;
  }

  /* _____________________________Let's Contact Us_____________________________ */
  .contactUs {
    padding: 50px 0px;
  }
  .formContactus input,
  .formContactus textarea {
    margin: 0px;
  }
  .quickContact {
    max-width: 100%;
    line-height: 31px;
  }
  .quickContact h2 {
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .quickContact p {
    font-size: 14px;
    line-height: 20px;
  }
  .contactUsIconContain span {
    font-size: 14px;
    line-height: 20px;
    max-width: 328px;
  }
  .formContactus .form-control {
    padding: 8px;
    font-size: 13px;
  }
  /* _____________________________Our Blog_____________________________ */
  .ourBlog {
    margin: 50px 0px;
  }
  .ourBlogCard {
    margin-top: 20px;
  }
  .ourBlogContain .ourBlogContainIcon {
    font-size: 12px;
    margin-top: 3px;
  }
  .ourBlogContain span {
    font-size: 12px;
  }
  .ourBlogContainIcon i {
    font-size: 10px;
  }
  .ourBlogCard .card-body {
    padding: 15px;
  }
  .ourBlogCard .card-title {
    font-size: 14px;
    line-height: 20px;
  }
  .ourBlogContain hr {
    margin: 4px 0px;
  }
  .ourBlogCard .card-text {
    line-height: 21px;
    font-size: 13px;
    text-align: justify;
  }

  /* _____________________________STARTED_____________________________ */
  .startedTodayMainDiv p {
    font-size: 16px;
  }
  .startedToday {
    margin-top: 50px;
    padding: 15px 0px;
  }
  .startedTodayMainDiv h6 {
    font-size: 14px;
  }
  /* ____________________Slider__________________ */
  .ourTeamMain .slick-next,
  .ourTestimonialMain .slick-next,
  .ourBlog .slick-next {
    width: 35px;
    height: 35px;
  }
  .ourTeamMain .slick-prev,
  .ourTestimonialMain .slick-prev,
  .ourBlog .slick-prev {
    width: 35px;
    height: 35px;
    right: 65px;
  }
  .ourTeamMain .slick-prev::before,
  .ourTeamMain .slick-next::before,
  .ourTestimonialMain .slick-prev::before,
  .ourTestimonialMain .slick-next::before,
  .aboutSlider .slick-prev::before,
  .aboutSlider .slick-next::before,
  .ourBlog .slick-prev::before,
  .ourBlog .slick-next::before {
    font-size: 18px;
  }
  .aboutSlider .slick-next {
    width: 35px;
    height: 35px;
  }
  .aboutSlider .slick-prev {
    width: 35px;
    height: 35px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  /* _____________________________navbar_____________________________ */

  /*_____________________________title_____________________________ */
  .titleFont h2 {
    font-size: 18px;
  }
  .titleFont h3 {
    font-size: 16px;
  }
  /*_____________________________features_____________________________ */
  .ourFeature h2 {
    max-width: 70%;
  }
  .featuresCard {
    margin-bottom: 15px;
  }
  .mainFeaturesCard {
    margin-top: 20px;
  }
  .ourFeature {
    padding: 30px 0px;
  }
  .featuresCard {
    padding: 15px;
    /* text-align: center; */
  }
  .featuresCardCircle {
    width: 80px;
    height: 80px;
  }
  .featuresCardContain h6 {
    font-size: 12px;
    line-height: 20px;
  }
  .featuresCardContain h3 {
    font-size: 16px;
    padding: 12px 0px 4px 0px;
  }
  .details .summary {
    padding-top: 0px;
    font-size: 14px;
  }
  .mainFeaturesCard button {
    margin-top: 30px;
  }
  .mainFeaturesCard button {
    margin-top: 0px;
  }
  /* _____________________________Year of experience_____________________________ */
  .yearOfExperience {
    text-align: center;
  }
  .homeYearExper {
    display: none;
  }
  .progress {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 320px;
  }
  .scrollbar {
    margin-top: 20px;
  }
  .scrollbar h3 {
    font-size: 14px;
    margin: 10px 0px -10px 0px;
    text-align: center;
  }

  .yearOfExperience img {
    float: none;
  }
  /* _____________________________aboutUs_____________________________ */
  .aboutSliderDiv {
    margin: 0 -15px;
  }
  .aboutSliderDiv img {
    padding: 0 15px !important;
  }
  .aboutUs {
    margin-left: 0px;
    margin-top: 20px;
  }
  .mainAboutUs {
    margin: 40px 0px;
  }
  .aboutIconCircle {
    width: 80px;
    height: 80px;
    margin-left: 7px;
    box-shadow: 0 0 7px #e2e1e1;
  }
  .aboutUsIconSideText h6 {
    font-size: 16px;
  }
  .aboutUs h5 {
    font-size: 12px;
    line-height: 20px;
    margin: 10px 0px;
  }
  .aboutUsIconSideText p {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
  .aboutIconMainDivFloat {
    margin: 15px 0px;
  }

  /* _____________________________Our Best Services_____________________________ */
  .ourBestServices .titleFont {
    max-width: 70%;
  }
  .ourBestServicesBanner {
    padding: 30px 0px;
  }
  .ourBestServicesRow {
    padding-top: 20px;
  }
  .ourBestServices .card-body {
    padding: 10px 13px;
  }
  .ourBestServices .card .card-title {
    font-size: 16px;
  }
  .ourBestServices .card .card-text {
    font-size: 13px;
    padding: 0px 0px;
    line-height: 20px;
    margin: 5px 0px;
  }
  .ourTestimonialCard .featuresCard {
    padding: 15px 15px;
  }
  /* _____________________________ Our Pricing _____________________________ */
  .ourPricingCard {
    margin-top: 15px;
  }
  .ourPricing {
    padding: 30px 0px;
  }
  .ourPricing .featuresCard {
    padding: 15px;
  }
  .ourPricingCard .featuresCard h2 {
    font-size: 25px;
    line-height: 40px;
  }
  .ourPricingCard .featuresCard span {
    font-size: 18px;
    margin-left: 5px;
  }
  .ourPricingCard .featuresCard h5 {
    font-size: 24px;
    line-height: 35px;
  }
  .ourPricingCard .featuresCard h6 {
    font-size: 16px;
  }
  .ourPricingCard .featuresCard ul li {
    font-size: 14px;
    line-height: 27px;
  }

  /* _____________________________our Team_____________________________ */
  .ourTeamMain .card-title {
    line-height: 25px;
    font-size: 16px;
  }
  .ourTeamMain .card-subtitle {
    font-size: 15px;
    line-height: 25px;
  }
  .ourTeamMain .card-body {
    padding: 7px;
  }
  .ourTeamMain {
    margin: 15px 0px 0px 0px;
  }
  /* _____________________________Our Testimonial_____________________________ */
  .ourTestimonialCard {
    margin-top: 15px;
  }
  .ourTestimonialCard .featuresCard {
    margin: 50px 0px 0px 0px;
  }
  .ourTestimonialMain {
    padding: 30px 0px;
  }
  .testimonialCard p {
    font-size: 14px;
    line-height: 25px;
  }
  .testimonialCard h6 {
    font-size: 16px;
  }

  /* _____________________________Let's Contact Us_____________________________ */
  .formContactus {
    max-width: 100%;
  }
  .contactUs {
    padding: 30px 0px;
  }
  .formContactus input,
  .formContactus textarea {
    margin: 0px;
  }
  .quickContact {
    max-width: 100%;
    line-height: 0px;
  }
  .quickContact h2 {
    font-size: 22px;
    margin-bottom: 4px;
    margin-top: 15px;
  }
  .quickContact p {
    font-size: 12px;
    line-height: 20px;
  }
  .contactUsIconContain span {
    font-size: 12px;
    line-height: 20px;
    max-width: 174px;
  }
  .formContactus .form-control {
    padding: 8px;
    font-size: 13px;
  }
  /* _____________________________Our Blog_____________________________ */
  .ourBlog {
    margin: 30px 0px;
  }
  .ourBlogCard {
    margin-top: 15px;
  }
  .ourBlogContain .ourBlogContainIcon {
    font-size: 12px;
    margin-top: 3px;
  }
  .ourBlogContain span {
    font-size: 12px;
  }
  .ourBlogContainIcon i {
    font-size: 10px;
  }
  .ourBlogCard .card-body {
    padding: 15px;
  }
  .ourBlogCard .card-title {
    font-size: 14px;
    line-height: 20px;
  }
  .ourBlogContain hr {
    margin: 4px 0px;
  }
  .ourBlogCard .card-text {
    line-height: 21px;
    font-size: 13px;
    text-align: justify;
  }
  .brandLogo .slick-prev {
    display: none !important;
  }
  .brandLogo .slick-next {
    display: none !important;
  }
  /* _____________________________ Brand Logo _____________________________ */

  .brandLogo img {
    max-width: 120px;
  }
  /* _____________________________STARTED_____________________________ */
  .startedTodayMainDiv p {
    font-size: 14px;
    max-width: 100%;
  }
  .startedToday {
    margin-top: 30px;
    padding: 15px 0px;
  }
  .startedTodayMainDiv h6 {
    font-size: 11px;
  }
  /* ____________________Slider__________________ */
  .ourTeamMain .slick-next,
  .ourTestimonialMain .slick-next,
  .ourBlog .slick-next {
    width: 32px;
    height: 32px;
  }
  .ourTeamMain .slick-prev,
  .ourTestimonialMain .slick-prev,
  .ourBlog .slick-prev {
    width: 32px;
    height: 32px;
    right: 60px;
  }
  .ourTeamMain .slick-prev::before,
  .ourTeamMain .slick-next::before,
  .ourTestimonialMain .slick-prev::before,
  .ourTestimonialMain .slick-next::before,
  .aboutSlider .slick-prev::before,
  .aboutSlider .slick-next::before,
  .ourBlog .slick-prev::before,
  .ourBlog .slick-next::before {
    font-size: 18px;
  }
  .aboutSlider .slick-next {
    width: 32px;
    height: 32px;
  }
  .aboutSlider .slick-prev {
    width: 32px;
    height: 32px;
  }
}
@media (min-width: 320px) and (max-width: 399px) {
  .header img {
    height: 25px;
  }
  .navbar {
    padding: 0;
  }
  /* _____________________________banner_____________________________ */
  .bannerContain h6 {
    font-size: 12px;
    line-height: 10px;
  }
  .header .ourSurvice {
    max-height: 30px;
    margin-top: 5px;
  }
  .bannerContain {
    top: 40%;
    left: 4%;
    max-width: 100%;
  }
  .bannerContain h1 {
    font-size: 15px;
    line-height: 15px;
  }
  .bannerContain h5 {
    display: none;
  }
  .bannerBtn {
    margin-top: 5px;
  }

  .ourSurvice {
    padding: 5px 10px;
    font-size: 12px;
  }
  .ourContact {
    display: none;
  }
}
@media (min-width: 400px) and (max-width: 575px) {
  .header img {
    height: 30px;
  }
  .header .ourSurvice {
    max-height: 30px;
    margin-top: 5px;
  }
  .navbar {
    padding: 0;
  }
  /* _____________________________banner_____________________________ */

  .bannerContain h6 {
    font-size: 12px;
    line-height: 10px;
  }
  .bannerContain {
    top: 40%;
    left: 4%;
    max-width: 50%;
  }
  .bannerContain h1 {
    font-size: 16px;
    line-height: 15px;
    margin-top: 13px;
  }
  .bannerContain h5 {
    display: none;
  }
  .bannerBtn {
    margin-top: 15px;
  }

  .ourSurvice,
  .ourContact {
    padding: 5px 10px;
    font-size: 12px;
  }
  .ourContact {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
}

.amazingExpertise .row {
  align-items: center;
  margin-top: 80px;
}
.amazingColContain {
  margin-left: 50px;
}
.amazingColContain .scrollbar {
  margin-top: 20px;
}
.servicesBanner {
  background-image: url(/static/media/ServicesBanner.b6b7a165.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 180px 0px;
  background-attachment: fixed;
}

.title {
  max-width: 30%;
  margin: 0 auto;
}
.servicesCards {
  margin-bottom: 80px;
}
.servicesExploreSolution h2 {
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
}
.servicesExploreSolution span {
  color: #fe0040;
  letter-spacing: 1px;
}
.servicesExploreSolution p {
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
}
.servicesCards .featuresCard {
  padding: 20px;
}
.featuresCard {
  text-align: center;
}
.featuresCard i {
  font-size: 50px;
  transition: 0.4s ease;
}
.featuresCard:hover i {
  /* color: #fe0040; */
  -webkit-transform: rotateY(3.142rad);
          transform: rotateY(3.142rad);
}
.servicesCards .featuresCardContain {
  text-align: center;
}
.servicesCards .featuresCard {
  margin: 20px 10px;
}
.servicesCards h3 {
  color: black;
  text-align: center;
}
.servicesCards #open {
  text-align: center;
  /* color: #fe0040; */
}

.servicesCards h3 {
  font-size: 22px;
}
#open i {
  font-size: 14px;
  margin-left: 5px;
  color: #000;
}
#open i:hover {
  /* transform: none; */
  color: #fe0040;
}

.amazingExpertise img{
  border-radius: 10px;
}
.amazingExpertise .beffect .bimg::after {
border-radius: 10px;
}
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

  .servicesExploreSolution h2 {
    font-size: 35px;
  }
}

/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

  .servicesCards h3 {
    font-size: 20px;
  }
}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  .servicesExploreSolution h2 {
    font-size: 36px;
  }
  .servicesExploreSolution p {
    font-size: 17px;
  }
  .featuresCard i {
    font-size: 45px;
  }
  .servicesCards h3 {
    font-size: 18px;
  }
  .servicesCards .featuresCard {
    padding: 12px;
  }
}

/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {

  .servicesExploreSolution h2 {
    font-size: 30px;
  }
  .servicesExploreSolution p {
    font-size: 16px;
  }
  .servicesCards h3 {
    font-size: 18px;
  }
  .featuresCard i {
    font-size: 40px;
  }
  .details .summary {
    font-size: 15px;
  }
  .amazingColContain {
    margin-left: 0px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .amazingColContain {
    margin-left: 0px;
    margin-top: 20px;
  }
  .servicesExploreSolution h2 {
    font-size: 30px;
  }
  .servicesExploreSolution p {
    font-size: 16px;
    margin-top: 0px;
  }
  .servicesCards .featuresCard {
    padding: 19px;
  }
  .servicesCards h3 {
    font-size: 18px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .amazingExpertise .row {
    margin-top: 30px;
  }
  .amazingExpertise .titleFont h3{
margin-top: 15px;
  }

  .servicesCards {
    margin-bottom: 40px;
}
  .amazingColContain {
    margin-left: 0px;
}
  .servicesExploreSolution h2 {
    font-size: 25px;
  }
  .servicesExploreSolution p {
    font-size: 13px;
    line-height: 15px;
    margin-top: 0px;
  }
  .servicesCards .featuresCard {
    padding: 10px;
    margin: 20px 0px;

  }
  .featuresCard i {
    font-size: 30px;
  }
  .servicesCards h3 {
    font-size: 15px;
  }
  .details .summary {
    font-size: 12px;
  }

}

.faqBanner {
  background-image: url(/static/media/FaqBannerImg.63f7dcdb.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 180px 0px;
  background-attachment: fixed;
}
.faqContain {
  margin-top: 80px;
  
}
.accordion-button:not(.collapsed) {
  color: #fe0040;
}
.accordion-button{
  background-color: #fff !important;
}
.accordion-item:first-of-type .accordion-button,
.accordion-button,
.accordion-button:focus {
  box-shadow: none;
}
.accordion-body {
  line-height: 35px;
  color: #666666;
}
.accordion-button:not(.collapsed)::after{
  -webkit-filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
          filter: invert(30%) grayscale(100%) brightness(70%) contrast(4);
}

/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {


.accordion-body {
  line-height: 30px;
  font-size: 14px;
}
.faqContain {
  margin-top: 60px;
}
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  .accordion-button {
    font-size: 14px;
  }
  .faqContain {
    margin-top: 50px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .accordion-button,
  .accordion-body {
    font-size: 13px;
  }
  .faqContain {
    margin-top: 40px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
.accordion {
  margin: 15px 0px;
}
  .accordion-button{
    font-size: 14px;
    font-weight: 500;
  }
  .accordion-body {
    font-size: 13px;
    line-height: 24px;
  }
  .accordion-item:first-of-type .accordion-button,.accordion-button {
    padding:10px;
  }
  .accordion-body{
padding: 10px;
padding-top: 0px;
  }
  .faqContain {
    margin-top: 30px;
  }
}

.planingBanner {
  background-image: url(/static/media/Planingbanner.24c2d996.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 180px 0px;
  background-attachment: fixed;
}
.planingIconMainDiv {
  padding: 80px 0px 0px 0px;
}
.planingIcon {
  text-align: center;
  padding: 35px;
  cursor: pointer;
}
.planingIcon:hover{
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 8%);
  border-radius: 10px;
}
.planingIcon i {
  font-size: 40px;
  color: #fe0040;
  transition: 0.3s ease;
}
.planingIcon:hover i{
  -webkit-transform: rotateY(3.142rad);
          transform: rotateY(3.142rad);
}
.planingIcon h2 {
  font-size: 20px;
  margin-top: 20px;
  font-family: 600;
}
.planingIcon h6 {
  font-size: 18px;
  line-height: 30px;
  color: #666666;
  font-weight: 300;
}
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

  .contactIconBgShadow h2 {
    font-size: 22px;
  }
}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {


  .planingIcon h2 {
    font-size: 18px;
  }
  .planingIcon h6 {
    font-size: 15px;
    line-height: 26px;
  }
  .planingIconMainDiv {
    padding: 60px 0px 0px 0px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {

  .planingIcon{
    padding: 5px;
  }
  .planingIcon h2 {
    font-size: 18px;
    margin-top: 15px;
  }
  .planingIcon h6 {
    font-size: 16px;
    line-height: 25px;
  }
  .planingIconMainDiv {
    padding: 50px 0px 0px 0px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {

  .planingIcon{
    padding: 20px;
  }
  .planingIcon h6 {
    font-size: 16px;
    line-height: 25px;
  }
  .planingIconMainDiv {
    padding: 40px 0px 0px 0px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .planingIconMainDiv{
    padding: 30px 0px 0px 0px;
  }
  .planingIcon h2 {
    font-size: 18px;
  }
  .planingIcon h6 {
    font-size: 14px;
    line-height: 20px;
  }
  .planingIcon {
    padding: 15px;
  }
}


.aboutMenu {
  margin: 80px 0px;
}
.aboutMenu p {
  max-width: 400px;
  color: #666666;
  line-height: 30px;
  margin-top: 20px;
}
/* _____________________video____________________ */
.video {
  position: relative;
  margin: 80px 0px;
}
.VideoIframe {
  overflow: hidden;
  border: none;
  width: 100%;
}
.modal-header {
  margin-bottom: -28px;
  border: none;
}

.Playbtn {
  position: absolute;
  /* z-index: 10; */
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  cursor: pointer;
}

.Playbtn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ef0040;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
          animation: pulse-border 1500ms ease-out infinite;
}

.Playbtn:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #ef0040;
  border-radius: 50%;
  transition: all 200ms;
}

.Playbtn:hover:after {
  background-color: darken(#ef0040, 10%);
}

.Playbtn i {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
            transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
    opacity: 0;
  }
}
.aboutusMenuCounting {
  text-align: center;
}
.aboutusMenuCounting h2 {
  font-size: 50px;
  font-weight: 600;
  cursor: pointer;
}
.aboutusMenuCounting h6 {
  font-size: 18px;
  color: #666666;
  font-weight: 400;
}
.video img {
  width: 100%;
  border-radius: 10px;

}
.aboutMenu img{
  border-radius: 10px;
}
 .aboutMenu .bimg::after {
  border-radius: 10px !important;
  }
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {

  .aboutusMenuCounting h2 {
    font-size: 46px;
  }
}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  .aboutusMenuCounting h2 {
    font-size: 40px;
  }
  .aboutMenu {
    margin: 60px 0px;
  }
  .aboutMenu p {
    line-height: 25px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
  }
  .video {
    margin: 60px 0px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  .aboutMenu p {
    font-size: 15px;
    line-height: 24px;
    margin-top: 0px;
  }
  .aboutusMenuCounting h2 {
    font-size: 35px;
  }
  .aboutusMenuCounting h6 {
    font-size: 16px;
  }
  .Playbtn {
    padding: 13px 15px 13px 23px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .aboutusMenuCounting h2 {
    font-size: 38px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {

  .aboutMenu {
    margin: 40px 0px;
}   
.colForResponsive{
  margin-top: 20px;
}
.aboutMenu p{
  margin-top: 0px;
  font-size: 14px;
  line-height: 25px;
}
  .aboutusMenuCounting h2 {
    font-size: 30px;
  }
  .aboutusMenuCounting h6 {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .Playbtn {
    width: 20px;
    height: 18px;
  }
  .Playbtn:before {
    width: 35px;
    height: 35px;
  }
  .Playbtn i {
    border-left: 12px solid #fff;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
  .Playbtn:after {
    width: 40px;
    height: 40px;
  }
  .video {
  position: relative;
  margin: 30px 0px;
}
.ourTeamMain {
  margin: 15px 0px 15px 0px;
}
}

.blog :hover .blog-post-hover {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.recent {
  position: relative;
  margin-bottom: 0;
  overflow: hidden;
}

.blog-post-hover {
  opacity: 0;
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  transform: scale(1);
  -webkit-transform: scale(0.8);
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px 10px 0 0;
}
.blog-icon {
  position: absolute;
  font-size: 20px;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  top: 50%;
  color: #FFF;
}
.paragraph {
  font-size: 14px;
  line-height: 30px;
  color: #707070;
  margin: 0px;
}
.blogSticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.blogCardMainClass {
  /* box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%); */
  margin: 13px 0;
  border-radius: 10px;
  border: 1px solid #e2e1e1;
}
.spacing {
  margin: 40px 0;
}
.blogpageFstContainBlog img {
  margin: 15px 0 !important;
  border-radius: 10px;
  text-align: center;
}
.blogpageFstContainBlog h5 {
  font-size: 22px;
  color: #fe0040;
  margin: 0;
  line-height: 30px;
  margin-bottom: 10px;
}
.blogpageFstContainBlog h2 {
  font-size: 27px;
  font-family: 600;
  margin: 0;
  line-height: 38px;
}
.blogpageFstContainBlog h3 {
  font-size: 16px;
  color: #707070;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}
.mainBlogBody {
  padding: 15px;
}
.mainBlog img{
  border-radius: 10px 10px 0 0;
}
.mainBlogBody h2 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 10px;
}

.mainBlogBody .ourBlogContainIcon {
  margin: 0 5px;
}
/* ____________________________________ */
.followUs h2,
.popularTags h2 {
  font-size: 24px;
  margin: 10px 0;
  font-weight: 600;
  text-align: left;
}
.sideblogbtn {
  padding: 10px 0;
}
.blogpage img {
  margin: 30px 0;
  border-radius: 10px;
}
.bloghr {
  height: 100%;
  width: 1px;
}
.blogpagesidemaindiv {
  margin-left: 50px;
}
.sideblogSubContain .row {
  align-items: center;
}
.blogSlider .progress {
  width: 100%;
  margin: 10px 0px 15px 0px;
}

.blogSlider .scrollbar h3 {
  font-size: 16px;
}
.popularTags {
  margin: 0px 0 30px 0px;}
.popularTags button {
  margin: 3px;
}
.blogpageIcon {
  background-color: #f5f5f5;
  margin: 7px -5px;
  cursor: pointer;
  padding:  10px;
  border-radius: 10px;
}
.blogpageIcon i {
  width: 35px;
  font-size: 18px;
  background-color: white;
  text-decoration: none;
  color: black;
  border-radius: 10px;
  padding: 7px 0px;
  text-align: center;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
  transition: 0.4s ease;
}
.blogpageIcon:hover i {
  color: #fe0040;
}
.blogsidefstpart .row {
  align-items: center;
}
.followUs {
  margin: 30px 0;
}
.blogpageIcon span {
  font-size: 14px;
  margin-left: 5px;
}
.blogsidefstpart .row{
  align-items: center;
}
.blogSlider .scrollbar h3{
margin: 0;
}
/* ________________Review__________________ */
.Review-box .Review-icon-person {
  color: #707070;
  opacity: 0.5;
  font-size: 50px;
  display: inline-block;
  vertical-align: middle;
}
.Course-review {
  margin: 15px 0;
}
.Course-review h3 {
  color: #fe0040;
  font-size: 22px;
  margin: 0;
}
.Course-review-star {
  color: #ffb608;
  font-size: 18px;
}

.Review-box {
  display: inline-block;
  border: 1px solid #c9c9c9;
  background-color: #f9f9f9;
  padding: 24px;
  margin: 10px 0;
  border-radius: 5px;
}
.Review-author {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.Review-content p {
  display: inline-block;
  margin: 0 auto;
  margin-left: 62px;
  vertical-align: top;
}
/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {
  .paragraph {
    line-height: 25px;
  }
}
/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  .paragraph {
    line-height: 25px;
  }
  .sideblogSubContain .paragraph{
    font-size: 12px;
    line-height: 20px;
  }
  .blogpage img {
    margin: 20px 0;
  }
  .blogpageIcon {
    margin: 3px -9px;
    padding: 6px;
  }
  .blogpageIcon i {
    width: 30px;
    font-size: 16px;
  }
  .blogpageIcon span {
    font-size: 13px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  .paragraph {
    line-height: 25px;
  }
  .blogpage .ourBlogContainIcon i {
  font-size: 16px;
}
  .followUs {
    margin: 0px 0;
  }
  .blogpage img {
    margin: 10px 0;
  }
  .popularTags {
  margin: 5px 0;
}

}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .paragraph {
    line-height: 21px;
    font-size: 13px;
  }
  .blogPage .ourBlogContain{
    display: block !important;
  }
  .blogPage .ourBlogContain .ourBlogContainIcon {
    float: none;
    display: inline-block !important;
  }
  .blogPage .mainBlogBody .ourBlogContainIcon {
    margin: 0 2px;
  }
.blogPage .mainBlogBody h2 {
margin: 0;
font-size: 17px;
}
.blogPage .ourBlogContain span {
  font-size: 13px;
}
.blogPage .blogdate{
margin-right: 25px;
font-size: 14px;
}
.blogPage hr{
  margin: 8px 0;
}
.blogpageFstContainBlog h2 {
  font-size: 22px;
  line-height: 30px;
}
.blogpageFstContainBlog h3 {
  font-size: 15px;
  line-height: 30px;
}
.blogpageFstContainBlog h5{
  margin-bottom: 5px;
}
.blogpage img {
  margin: 15px 0;
}
.sideblogSubContain .paragraph {
  line-height: 18px;
  font-size: 12px;
}
.Review-box{
padding: 18px;
}
.Review-box .Review-icon-person {
  font-size: 45px;
}
.Review-author h6{
  margin: 0;
}
.Course-review-star {
  color: #ffb608;
  font-size: 15px;
}
.blogsidefstpart {
  text-align: center ;
}
.blogSlider{
  text-align: left;
}
.popularTags {
  margin: 0px 0;
}
.followUs h2, .popularTags h2 {
  margin: 0px 0px 5px 0;
}
.blogpage .ourBlogContainIcon i {
  font-size: 16px;
}
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .paragraph {
    line-height: 21px;
    font-size: 13px;
  }
  .blogpageFstContainBlog h2 {
    font-size: 18px;
    line-height: 25px;
  }
  .blogpageFstContainBlog h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .ourBlogContain span {
    font-size: 14px;
  }
  .blogpageFstContainBlog h5,.Course-review h3  {
    font-size: 18px;
    margin: 0;
  }
  .blogpageFstContainBlog img {
    margin: 10px 0 !important;
  }  
  .blogpage img {
    margin: 15px 0;
}
.sideblogSubContain .paragraph{
margin-bottom: 15px;
}
.Review-content p{
  margin: 0;
}
.Review-box{
  padding: 10px;
}
.blogSlider .scrollbar h3 {
  font-size: 14px;
  margin: 10px 0px -6px 0px;
  text-align: left;
}
.blogSlider .progress {
  width: 100%;
  margin: 8px 0px 13px 0px;
}
.followUs {
  margin: 30px 0;
  margin-bottom: 15px;
}
.followUs h2, .popularTags h2 {
  font-size: 20px;
  color: #fe0040;
}
.blogpageIcon i {
  width: 30px;
  font-size: 15px;
}
.blogpageIcon{
  padding: 5px;
}
.blogpageIcon span {
  font-size: 12px;
}
.blogpage hr{
  margin: 8px 0;
 
}
.blogPage .startedEmailBox {
  margin-top: 0px !important;
}
.popularTags {
  margin: 15px 0;
}
.mainBlogBody hr{
margin: 10px 0;
}
.blogpage .ourBlogContainIcon i {
  font-size: 13px;
}
}
@media (min-width: 320px) and (max-width: 403px) {
  .blogPage .ourBlogContain{
    display: block !important;
  }
  .blogPage .ourBlogContain .ourBlogContainIcon {
    float: none;
    display: inline-block !important;
  }
  .blogPage .mainBlogBody .ourBlogContainIcon {
    margin: 0 2px;
  }
  .blogPage .mainBlogBody h2 {
  margin: 0;
  font-size: 17px;
  }
  .blogPage .ourBlogContain span {
  font-size: 13px;
  }
  .blogPage .blogdate{
  margin-right: 100%;
  font-size: 14px;
  }
  .blogPage hr{
  margin: 8px 0;
  }
}
@media (min-width: 404px) and (max-width: 575px) {}


